
// React
import React, { useContext, useEffect, useState } from 'react';

// Bootstrap related
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

// Helpers
import { customAPI } from '../../helpers/api.js';
import { formatDate } from '../../helpers/DateFunctions.js';

// Context
import { currentUserContext } from '../../helpers/currentUserProvider.js';




export const NavNotificationDropdown = ({ teams, options }) => {

    const [currentUser] = useContext(currentUserContext);

    const [notifications, setNotifications] = useState();

    const notificationItems = [];


    useEffect(() => {

        let params = [];

        params.push('paging[count]=5');

        customAPI.get('activity/me' + (params.length > 0 ? '?' + params.join('&') : ''), currentUser, (result) => {
            setNotifications(result);
        }, (error) => {
            // 
        });

    }, [currentUser]);




    notificationItems.push(
        <NavDropdown.Item href="/activity" className="d-flex align-items-center  py-3" key="not-0">
            <span className="material-symbols-outlined me-1">menu</span>
            View all activity
        </NavDropdown.Item>
    )

    if (notifications){

        notifications.forEach((notification) => {
            notificationItems.push(
                <NavDropdown.Item href={'/tasks/' + notification.taskid + '/update'} key={'not-' + notification.activityid}>
                    {notification.details}
                    <span className="notification__info smaller uppercase">
                        {/* 26/09, 12:38 pm */}
                        {notification.userName} • {formatDate(notification.dateAdded, 'dd/MM, HH:mm a')}
                    </span>
                </NavDropdown.Item>
            );
        });
    }

    return (
        <Nav className="navbar-notifications navbar-right">
            <NavDropdown title={<span className="material-symbols-outlined">notifications</span>} id="not-navbar-dropdown">
                {notificationItems}
            </NavDropdown>
        </Nav>
    );

};
