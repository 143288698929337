
import { format, formatDistance, formatRelative, addDays, subDays, getWeek, getISOWeek, startOfWeek, fromUnixTime } from 'date-fns'
import { enGB } from 'date-fns/locale';


export function renderDate(dateString){

    if (dateString === '0000-00-00 00:00:00'){
        return '-';
    }

    return new Date(dateString).toLocaleString();
}

export function formatDate(dateObject, dateFormat = 'yyyy-MM-dd', timezone = 'London/Europe'){

    if (typeof dateObject !== 'object'){
        dateObject = new Date(dateObject);
    }

    return format(dateObject, dateFormat );
}

export function renderTimeSince(dateString){ 

    if (dateString === '0000-00-00 00:00:00'){
        return '-';
    }

    return formatRelative(new Date(dateString), new Date(), { locale: enGB } );
}

export function addDaysToDate( dateParam, dayCount){ 

    if (dateParam === '0000-00-00 00:00:00'){
        return '-';
    }

    if (typeof dateParam === 'string'){
        return addDays( new Date(dateParam), dayCount );
    } else {
        return addDays( dateParam, dayCount );
    }

}

export function subtractDaysFromDate( dateParam, dayCount){ 

    if (dateParam === '0000-00-00 00:00:00'){
        return '-';
    }

    if (typeof dateParam === 'string'){
        return subDays( new Date(dateParam), dayCount );
    } else {
        return subDays( dateParam, dayCount );
    }

}

export function thisWeek( dateParam ){

    if (dateParam === '0000-00-00 00:00:00'){
        return '-';
    }

    if (typeof dateParam === 'string'){
        return startOfWeek( new Date(dateParam), { weekStartsOn: 1 } );
    } else {
        return startOfWeek( dateParam, { weekStartsOn: 1 } );
    }
}




export function convertTime( t, options = {} ){



	let defaults = {
		format: (options && typeof options.format !== 'undefined' ? options.format : 'default'),
		showdays: (options && typeof options.showdays !== 'undefined' ? options.showdays : true),
		debug: (options && typeof options.debug !== 'undefined' ? options.debug : false),
        zeroresponse: (options && typeof options.zeroresponse !== 'undefined' ? options.zeroresponse : 'Just a reminder')
    };

    if (t === 0){
        return options.zeroresponse;
	}

    let min = 'min';
    let hour = 'hour';
    let day = 'day';
    let separator = ' ';
    let str = '';
    let tmp = 0;
    let tOriginal = t;

	// merge defaults and options

	switch(options.format){

		case "short":
		 	min = 'm';
			hour = 'h';
			day = 'day';
			separator = ' ';
			break;

        default: 
            break;

	}

	

	if (options.showdays && options.showdays === true && t > 450){

		// TODO:
		// Should this be working hours per day?
		// t = (t / (24 * 60));
		t = (t / 450);
		
		tmp = parseInt(t);
		
		str += tmp.toFixed(0) + ' ' + day + (tmp === 1 ? '' : 's');
		
		if (tmp !== t){

			t = t - tmp;

			// t is currently between 0 and 1. This proportion of an hour.
			t = 7.5 * t;
			
			tmp = parseInt(t);
			
			str += separator + tmp + separator + hour + ('short' == options.format || tmp == 1 ? '' : 's');

		}


	} else if (t > 59){
		
		// 65/60 = 1.08333333
		// 70/60 = 1.16666666
		t = parseFloat(t / 60);
		
		// floor(1.0833333) = 1
		// floor(1.1666666) = 1
		tmp = parseFloat(Math.floor(t));
		
		str = parseInt(tmp,0,".",",") + separator + hour + ('short' === options.format || tmp === 1 ? '' : 's');
		
		// 1.0833333 <> 1
		// 1.1666666 <> 1
		if (tmp !== t){

			// intval( 0.8333 * 60 )
			// intval( 0.1666 * 60 )
			// echo '<!-- ((t - tmp) * 60) = '.((t - tmp) * 60).', intval() = '.intval((t - tmp) * 60).', ceil() = '.ceil((t - tmp) * 60).' -->';
			str += separator + ((t - tmp) * 60).toFixed(0) + separator + min;

		}

		if (options.showdays){
			if (tOriginal > 450){
				str += ' (' + (tOriginal / 450).toFixed(1) + ' ' + day + 's)';	
			}
		}

	} else {
		str = parseInt(t) + separator + min;
	}

	return str;

}


export function convertTimeOld( t ){

    let min = 'min';
    let hour = 'hour';
    let day = 'day';
    let separator = ' ';
    let str = '';
    let tmp = 0;

    if (t === 0){
        return 'Just a reminder';
    } else {


        if (t > 59){
            
            t = t / 60;

            tmp = parseInt(t);
            str = parseInt(tmp) + separator + hour;
            if (tmp !== 1){
                str += 's';
            }
            
            if (tmp !== t){
                // str += separator + parseInt((t - tmp) * 60) + separator + min;
                str += separator + Math.round((t - tmp) * 60) + separator + min;
            }

        } else {
            str = parseInt(t) + separator + min;
        }

        return str;
    }
}


export function getWeekNumber(  selectedDate, timeZone ){

    // Copy date so don't modify original
    // selectedDate = new Date( Date.UTC( selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() ) );

    // Get first day of year
    // var yearStart = new Date( Date.UTC( selectedDate.getUTCFullYear(), 0, 1 ) );

    let weekNo = getISOWeek( selectedDate, { weekStartsOn: 1, locale: timeZone } );
    
    // var weekNo = Math.ceil( ( ( ( selectedDate - yearStart ) / 86400000 ) + 1 ) / 7 );



    // Return array of year and week number
    return [selectedDate.getUTCFullYear(), weekNo];
}


export function echoDate( format, dateStamp ){
  var newFixedDate = new Date( dateStamp );
  return newFixedDate.toString( format );
}


export function subtractWeeks( date, num ){

}
export function addWeeks( date, num ){

}


