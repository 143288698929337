
import { createContext, useState } from 'react';

export const currentUserContext = createContext();

export const hasPermission = ( permissions, permission ) => {
    
    if (!permissions){
        return false;
    }

    for (let p = 0;p < permissions.length; p++){
        if (permissions[p].urlslug === permission){
            if (permissions[p].granted === 1){
                return true;
            } else {
                return false;
            }
        }
    }

    return false;
}


const CurrentUserProvider = (props) => {


    const [currentUser, setCurrentUser] = useState({});
    /*
    const [currentUser, setCurrentUser] = useState({
        userid: 1,
        name: 'Jon Harvey',
        firstname: 'Jon',
        surname: 'Harvey',
        email: 'jon@waters-creative.co.uk',
        userlevelid: 20,
        dob: '1981-07-11',
        role: {
            permissions: []
        },
        teams: [
            {
                teamid: 18,
                name: 'Web',
                users: [
                    {
                        userid: 1,
                        name: 'Jon'
                    },
                    {
                        userid: 5,
                        name: 'Joe'
                    },
                    {
                        userid: 6,
                        name: 'John'
                    }
                ]
            },
            {
                teamid: 15,
                name: 'Waters',
                users: [
                    {
                        userid: 1,
                        name: 'Jon'
                    },                    
                    {
                        userid: 4,
                        name: 'Jane'
                    },
                    {
                        userid: 2,
                        name: 'Joan'
                    },
                    {
                        userid: 3,
                        name: 'Jenny'
                    }
                ]
            }
        ],
        // Access Token
        hash: (process.env.NODE_ENV === 'production' ? 'qTE6iTTO1IMACiezHQqhe74GOMujwX0O7WIyqccLsbfn9NknuJ' : 'RTAFicvi8HSYjL5Lz6miEylZRiUlZpi71dbJD2J1ZH3yPOmAkZ')
    });
    */

    return (
        <currentUserContext.Provider value={ [currentUser, setCurrentUser] }>
            {props.children}
        </currentUserContext.Provider>
    );

}

export default CurrentUserProvider;