// @flow
import React from 'react';

/**
 * Render error message:
 */
const Maintenance = ({ error }) => {

    if (error && error.message){
        return (
            <div className="panel danger">
                <h2>An error occured</h2>
                <p>{error.message}</p>
            </div>
        );
    } else if (typeof error === "string"){
        return (
            <div className="panel danger">
                <h2 className="mb-2">Error</h2>
                <p>{error}</p>
            </div>
        );    
    } else {
        return (
            <div className="panel danger">
                <h2>An unknown error has occured.</h2>
            </div>
        );
    }

};

export default Maintenance;
