
import config from '../config.js';
import download from 'downloadjs';



export const customAPI = {}


customAPI.maybeJSON = async response => {
    const text = await response.text()
    try{
        const json = JSON.parse(text)
        return json
    } catch(err) {
        throw new Error("A badly formatted response was recevied from the server (not in the expected format): `" + text + "`");
    }
}


customAPI.get = ( path, currentUser, successFn, failureFn, debugMode = true, includeVersion = true ) => {

    if (!path){
        return failureFn('No API path supplied.');
    }

    if (config.DEBUG_MODE){
        if (path.indexOf('?') > 0){
            path += '&XDEBUG_TRIGGER=true';
        } else {
            path += '?XDEBUG_TRIGGER=true';
        }
    }

    fetch(config.API_URL + '/' + (includeVersion === true ? config.API_VERSION + '/' : '') + path, {  
        method: 'GET', 
        mode: "cors",
        headers: {
            'Authorization': `Bearer: ` + currentUser.hash,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    })
        .then(customAPI.maybeJSON)
        .then(
            (result) => {
                if (debugMode){
                    console.info('API DEBUG (' + path + ', get): Response received: ', result);
                }                
                if (typeof result.error !== "undefined"){
                    failureFn(result.error);
                } else {
                    successFn(result.result);
                }
            },
            (error) => {
                if (debugMode){
                    console.info('API DEBUG (' + path + ', get): Error in request: ', error);
                }
                failureFn(error);
            }
        );
}




customAPI.post = ( path, data, currentUser, successFn, failureFn, debugMode = true, includeVersion = true ) => {

    if (!path){
        return failureFn('No API path supplied.');
    }

    if (!data){
        return failureFn('No data supplied.');
    }

    if (config.DEBUG_MODE){
        if (path.indexOf('?') > 0){
            path += '&XDEBUG_TRIGGER=true';
        } else {
            path += '?XDEBUG_TRIGGER=true';
        }
    }

    fetch(config.API_URL + '/' + (includeVersion === true ? config.API_VERSION + '/' : '') + path, {  
        method: 'POST', 
        body: JSON.stringify(data), 
        mode: "cors",
        headers: {
            'Authorization': `Bearer: ` + currentUser.hash,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    })
        .then(res => res.json())
        .then(
            (result) => {
                if (config.DEBUG_MODE){
                    console.info('API DEBUG (' + path + ', post): Response received: ', result);
                }
                if (typeof result.error !== "undefined"){
                    failureFn(result.error);
                } else {
                    successFn(result.result);
                }
            },
            (error) => {
                if (config.DEBUG_MODE){
                    console.info('API DEBUG (' + path + ', post): Error in request: ', error);
                }
                failureFn(error);
            }
        );
 
}


/*
customAPI.post = ( path, data, currentUser, successFn, failureFn, debugMode = true ) => {

    fetch(config.API_URL + '/' + (includeVersion === true ? config.API_VERSION + '/' : '') + path, {  
        method: 'GET', 
        mode: "cors",
        headers: {
            'Authorization': `Bearer: ` + currentUser.hash,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    })
        .then(res => res.json())
        .then(
            (result) => {
                if (debugMode){
                    console.info('API DEBUG (' + path + ', get): Response received: ', result);
                }                
                if (typeof result.error !== "undefined"){
                    failureFn(result.error);
                } else {
                    successFn(result.result);
                }
            },
            (error) => {
                if (debugMode){
                    console.info('API DEBUG (' + path + ', get): Error in request: ', error);
                }
                failureFn(error);
            }
        );
}
*/


customAPI.postFile = ( path, data, currentUser, successFn, failureFn, debugMode = true, includeVersion = true ) => {

    if (!path){
        return failureFn('No API path supplied.');
    }

    if (!data){
        return failureFn('No data supplied.');
    }

    if (config.DEBUG_MODE){
        if (path.indexOf('?') > 0){
            path += '&XDEBUG_TRIGGER=true';
        } else {
            path += '?XDEBUG_TRIGGER=true';
        }
    }

    fetch(config.API_URL + '/' + (includeVersion === true ? config.API_VERSION + '/' : '') + path, {  
        method: 'POST', 
        body: data, 
        mode: "cors",
        headers: {
            'Authorization': `Bearer: ` + currentUser.hash,
            // 'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
        .then(customAPI.maybeJSON)
        .then(
            (result) => {
                if (config.DEBUG_MODE){
                    console.info('API DEBUG (' + path + ', post): Response received: ', result);
                }
                if (typeof result.error !== "undefined"){
                    failureFn(result.error);
                } else {
                    successFn(result.result);
                }
            },
            (error) => {
                if (config.DEBUG_MODE){
                    console.info('API DEBUG (' + path + ', post): Error in request: ', error);
                }
                failureFn(error);
            }
        );
 
}



customAPI.patch = ( path, data, currentUser, successFn, failureFn, debugMode = true, includeVersion = true ) => {

    if (!path){
        return failureFn('No API path supplied.');
    }

    if (!data){
        return failureFn('No data supplied.');
    }

    if (config.DEBUG_MODE){
        if (path.indexOf('?') > 0){
            path += '&XDEBUG_TRIGGER=true';
        } else {
            path += '?XDEBUG_TRIGGER=true';
        }
    }

    fetch(config.API_URL + '/' + (includeVersion === true ? config.API_VERSION + '/' : '') + path, {  
        method: 'PATCH', 
        body: JSON.stringify(data), 
        mode: "cors",
        headers: {
            'Authorization': `Bearer: ` + currentUser.hash,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    })
        .then(customAPI.maybeJSON)
        .then(
            (result) => {
                if (debugMode){
                    console.info('API DEBUG (' + path + ', patch): Response received: ', result);
                }
                if (typeof result.error !== "undefined"){
                    failureFn(result.error);
                } else {
                    successFn(result.result);
                }
            },
            (error) => {
                if (debugMode){
                    console.info('API DEBUG (' + path + ', patch): Error in request: ', error);
                }
                failureFn(error);
            }
        );

}



customAPI.delete = ( path, data, currentUser, successFn, failureFn, debugMode = true, includeVersion = true ) => {

    if (!path){
        return failureFn('No API path supplied.');
    }

    if (config.DEBUG_MODE){
        if (path.indexOf('?') > 0){
            path += '&XDEBUG_TRIGGER=true';
        } else {
            path += '?XDEBUG_TRIGGER=true';
        }
    }

    fetch(config.API_URL + '/' + (includeVersion === true ? config.API_VERSION + '/' : '') + path, {  
        method: 'DELETE', 
        body: JSON.stringify(data), 
        mode: "cors",
        headers: {
            'Authorization': `Bearer: ` + currentUser.hash,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    })
        .then(customAPI.maybeJSON)
        .then(
            (result) => {
                if (debugMode){
                    console.info('API DEBUG (' + path + ', delete): Response received: ', result);
                }
                if (typeof result.error !== "undefined"){
                    failureFn(result.error);
                } else {
                    successFn(result.result);
                }
            },
            (error) => {
                if (debugMode){
                    console.info('API DEBUG (' + path + ', delete): Error in request: ', error);
                }
                failureFn(error);
            }
        );
}

customAPI.getDownload = ( path, currentUser, successFn, failureFn, debugMode = true ) => {
    customAPI.handleDownload( 'get', path, null, currentUser, successFn, failureFn, debugMode = true );
}

customAPI.postDownload = ( path, data, currentUser, successFn, failureFn, debugMode = true ) => {
    customAPI.handleDownload( 'post', path, data, currentUser, successFn, failureFn, debugMode = true );
}

customAPI.handleDownload = ( method, path, data, currentUser, successFn, failureFn, debugMode = true, includeVersion = true ) => {

    if (!path){
        return failureFn('No API path supplied.');
    }

    if (config.DEBUG_MODE){
        if (path.indexOf('?') > 0){
            path += '&XDEBUG_TRIGGER=true';
        } else {
            path += '?XDEBUG_TRIGGER=true';
        }
    }

    fetch(config.API_URL + '/' + (includeVersion === true ? config.API_VERSION + '/' : '') + path, {  
        method: method, 
        mode: "cors",
        body: (data ? JSON.stringify(data) : null),
        headers: {
            'Authorization': `Bearer: ` + currentUser.hash
        }
    })
        .then(res => res.blob())
        .then(
            (result) => {
                successFn({
                    success: true
                });
                download( result, data.filename, data.mimetype );
            },
            (error) => {
                if (debugMode){
                    console.info('API DEBUG (' + path + ', get): Error in request: ', error);
                }
                failureFn(error);
            }
        );
}

