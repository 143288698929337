
import { createSlice } from '@reduxjs/toolkit'

export const messagesSlice = createSlice({

    name: 'messages',

    initialState: {
        messages: []
    },

    reducers: {

        addMessage: (state, action) => {
            let newMessage = {
                className: 'text-white bg-success',
                ...action.payload
            }
            state.messages.push(newMessage);
        },

        addErrorMessage: (state, action) => {
            let newMessage = {
                className: 'text-white bg-danger',
                ...action.payload
            }
            state.messages.push(newMessage);
        },

        clearMessages: (state, action) => {
            state.messages = [];
        } 
        
    }
})

export const { 
    addMessage,
    addErrorMessage,
    clearMessages
} = messagesSlice.actions;

export const getMessages = state => state.messages.messages;

export const getState = state => state.messages.messages;

export default messagesSlice.reducer;
