
import { createSlice } from '@reduxjs/toolkit';

// Time related
import { format, formatDistance, formatRelative, addDays, subWeeks, addWeeks, subDays, getWeek , startOfWeek, fromUnixTime } from 'date-fns'
import { enGB } from 'date-fns/locale';

// Date
import { getWeekNumber } from '../helpers/DateFunctions';


export const calendarSlice = createSlice({

    name: 'calendar',

    initialState: {
        year: 0,
        week: 0,
        userid: 0,
        startOfWeek: startOfWeek( new Date() ).toISOString(),
        timezone: 'London/Europe',
        weekStatsOn: 1,
        refresh: 0
    },

    reducers: {


        addWeek: (state) => {

            let sowDt = addWeeks( new Date(state.startOfWeek), 1 );

            let [year, week] = getWeekNumber( sowDt, state.timezone );
            state.year = year;
            state.week = week;

            state.startOfWeek = sowDt.toISOString();
            
        },

        subtractWeek: (state) => {

            let sowDt = subWeeks( new Date(state.startOfWeek), 1 );
            
            let [year, week] = getWeekNumber( sowDt, state.timezone );
            state.year = year;
            state.week = week;

            state.startOfWeek = sowDt.toISOString();

        },

        setDate: (state, action) => {

            if (action.payload.timezone){
                state.timezone = action.payload.timezone;
            }

            // date object
            if (action.payload.date){

                let sowDt = startOfWeek( new Date(action.payload.date), { weekStartsOn: state.weekStatsOn } );

                let [year, week] = getWeekNumber( sowDt, state.timezone );
                state.year = year;
                state.week = week;

                state.startOfWeek = sowDt.toISOString();

            }

        },


        finishRefresh: (state) => {
            state.refresh = 'loaded';
        },

        triggerRefresh: (state) => {
            console.info('triggerRefresh()');
            state.refresh++;
        }


    }
})

export const { 
    addWeek,
    subtractWeek,
    setDate,
    finishRefresh,
    triggerRefresh
} = calendarSlice.actions;




export const getRefreshState = (state) => {
    if (!state || !state.calendar){
        return false;
    }
    return state.calendar.refresh;
}

export const getState = (state) => {
    if (!state || !state.calendar){
        return false;
    }
    return state.calendar;
}

export default calendarSlice.reducer;
