// @flow
import React from 'react';

/**
 * Renders the preloader
 */
const ToDo = ({ children }) => {
    return (
        <div className="position-relative  todo p-3">

            <div className="d-flex align-items-center justify-content-center  bg-light  py-3">

                {children ? (
                    <>
                        {children}
                    </>
                ) : (
                    <span>
                        Coming soon...
                    </span>
                )}
            </div>

        </div>
    );
};

export default ToDo;
