
// React
import React, { useContext } from 'react';

// Bootstrap related
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

// Context
import { currentUserContext } from '../../helpers/currentUserProvider.js';




const NavProfileMenu = ({ options }) => {


    const [currentUser] = useContext(currentUserContext);


    const profileNavItems = [];


    if (currentUser){


        profileNavItems.push(
            <NavDropdown.Item href="/activity" key="profile-nav-activity">
                Activity
            </NavDropdown.Item>
        );

        profileNavItems.push(
            <NavDropdown.Item href="/deletions" key="profile-nav-deletions">
                Deletions
            </NavDropdown.Item>
        );

        profileNavItems.push(
            <NavDropdown.Item href="/profile" key="profile-nav-profile">
                Profile
            </NavDropdown.Item>
        );

        profileNavItems.push(
            <NavDropdown.Item href="/help" key="profile-nav-help">
                Help
            </NavDropdown.Item>
        );

        profileNavItems.push(
            <NavDropdown.Divider key="profile-nav-divider-1" />
        );

        profileNavItems.push(
            <NavDropdown.Item href="/logout" key="profile-nav-logout">
                Logout
            </NavDropdown.Item>
        );


        return (
            <Nav className="navbar-right">
                <NavDropdown title={currentUser.name} id="not-navbar-dropdown">
                    {profileNavItems}
                </NavDropdown>
            </Nav>
        );

    } else {
        return(
            <Nav className="">Loading...</Nav>
        )
    }

};

export default NavProfileMenu;
