
import React, { useContext, useState, useEffect } from 'react';
import {Link} from 'react-router-dom';

// Components
import Logo from '../../components/Logo.js';
import PrivateMenu from './PrivateMenu.js';
import PublicMenu from '../public/PublicMenu.js';
import { NavNotificationDropdown } from '../private/NavNotificationDropdown.js';
import NavSearch from '../private/NavSearch.js';
import NavProfileMenu from '../private/NavProfileMenu.js';

// Bootstrap related
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';


// Helpers

// Global contexts
import { currentUserContext } from '../../helpers/currentUserProvider.js';


const PrivateHeader = () => {


    const [ currentUser, setCurrentUser ] = useContext( currentUserContext );

    const teamNavItems = []

    teamNavItems.push(
        <NavDropdown.Item href="/teams" key={'team-nav-0'} className="dropdown">View all...</NavDropdown.Item>
    );

    if (currentUser.teams){
        currentUser.teams.forEach((team) => {


            let members = [];

            members.push(
                <NavDropdown.Item 
                    href={'/teams/' + team.teamid}
                    key={'team-' + team.teamid + '-overview'}>
                        Overview...
                </NavDropdown.Item>
            );


            if (team.users && team.users.length > 0){
                team.users.forEach((user) => {

                    if (user.userid === currentUser.userid){
                        members.push(
                            <NavDropdown.Item 
                                key={'team-' + team.teamid + '-' + user.userid}>
                                    {user.name} (You)
                            </NavDropdown.Item>
                        );
                    } else {
                        members.push(
                            <NavDropdown.Item 
                                href={'/calendar/user/' + user.userid} 
                                key={'team-' + team.teamid + '-' + user.userid}>
                                    {user.name}
                            </NavDropdown.Item>
                        );
                    }

                })
            }

            teamNavItems.push(
                <NavDropdown key={'team-nav-' + team.teamid} title={team.name} id={'team-dropdown-' + team.teamid}>
                    {members}
                </NavDropdown>
            );

        });
    }


    return (
        <header id="branding" className="region">
            {/* <div className="container-fluid  container--branding"> */}
                
                <Navbar className="navbar navbar-expand navbar-inverse" id="nav">
                
                    <Navbar.Brand>
                        <Logo />
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />

                    <Navbar.Collapse id="basic-navbar-nav">
 
                        <Nav className="me-auto">

                            <Nav.Link href="/dashboard">Dashboard</Nav.Link>

                            <Nav.Link href="/calendar">Calendar</Nav.Link>

                            <Nav.Link href="/projects">Projects</Nav.Link>

                            <NavDropdown title="Teams" id="team-navbar-dropdown">
                                {teamNavItems}
                            </NavDropdown>

                            <NavDropdown title="More..." id="more-navbar-dropdown">

                                <NavDropdown.Item href="/reports">Reports</NavDropdown.Item>
                                <NavDropdown.Item href="/test">API</NavDropdown.Item>
                                <NavDropdown.Item href="/widgets">Widgets test</NavDropdown.Item>

                            </NavDropdown>

                        </Nav>

                        <NavNotificationDropdown teams={currentUser.teams} options={{}} />

                        <NavSearch options={{}} />

                        <NavProfileMenu options={{}} />

                    </Navbar.Collapse>

                </Navbar>                

            {/* </div> */}
        </header>

    )

}

export default PrivateHeader;
