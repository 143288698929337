
import { createSlice } from '@reduxjs/toolkit'

export const viewPanelSlice = createSlice({

    name: 'viewpanel',

    initialState: {
        isOpen: false,
        title: 'Panel',
        children: null
    },

    reducers: {

        triggerOpen: (state, action) => {

            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            if (action.payload.isOpen){
                state.isOpen = action.payload.isOpen;
            }
            if (action.payload.title){
                state.title = action.payload.title;
            }
            if (action.payload.children){
                state.children = action.payload.children;
            }

        },

        triggerClose: state => {
            state.children = null;
            state.isOpen = false;
        },

        setTitle: (state, action) => {
            state.title = action.payload;
        },

        setChildren: (state, action) => {
            state.children = action.payload;
        }
        
    }
})

export const { 
    triggerOpen,
    triggerClose,
    setTitle,
    setChildren 
} = viewPanelSlice.actions;

export const getOpenState = state => state.viewPanel.isOpen;

export const getState = state => state.viewPanel;

export default viewPanelSlice.reducer
