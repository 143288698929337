import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

// import './i18n';

import App from './App';

// import { Provider } from 'react-redux';
// import { configureStore } from './redux/store';

// ReactDOM.render(
//     <Provider store={configureStore({})}>
//         <App />
//     </Provider>,
//     document.getElementById('root')
// );


const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
// root.render(
//     <Provider store={configureStore({})}>
//         <App />
//     </Provider>
// );
root.render(
    <App />
);
