
import { BrowserRouter } from 'react-router-dom';
import { AllRoutes } from './index';

import CurrentCompanyProvider from './../helpers/currentCompanyProvider.js';
import CurrentUserProvider from './../helpers/currentUserProvider.js';
import StateProvider from '../helpers/stateProvider.js';


const Routes = () => {
    return (
        <BrowserRouter>
        
            <CurrentCompanyProvider>
                <CurrentUserProvider>
                    <StateProvider>

                        <AllRoutes />

                    </StateProvider>
                </CurrentUserProvider>
            </CurrentCompanyProvider>
            
        </BrowserRouter> 
    );
};

export default Routes;
