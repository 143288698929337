
// React
import React, { useContext, useState, useEffect } from 'react';
import {Link} from 'react-router-dom';

// Components
import Logo from '../../components/Logo.js';

// Bootstrap related
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

// Context
import { currentUserContext } from '../../helpers/currentUserProvider.js';
 



const PublicHeader = () => {


    return (
        <header id="branding" className="header region">
            <div className="container-fluid  container--branding">
 
                <Navbar expand="lg" className="navbar-inverse" id="nav">
                
                    <Navbar.Brand>
                        <Logo />
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="primary-navbar-nav">
                        <span className="material-symbols-outlined">menu</span>
                    </Navbar.Toggle>

                    <Navbar.Collapse id="primary-navbar-nav">
                        
                        <ul className="navbar-nav  me-xl-auto  mb-2 mb-lg-0">

                            <li className="nav-item">
                                <Link to="/" className="nav-link">Home</Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/login" className="nav-link">Logon</Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/register" className="nav-link">Register</Link>
                            </li>

                            {/* <li className="nav-item">
                                <Link to="/about" className="nav-link">About</Link>
                            </li> */}

                            {/* <li className="nav-item">
                                <Link to="/help" className="nav-link">Help</Link>
                            </li> */}

                        </ul>

                    </Navbar.Collapse>

                </Navbar>

            </div>
        </header>

    )

}

export default PublicHeader;
