
import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
// import config from '../config.js';
import { customAPI } from '../helpers/api';
// import { useSelector } from 'react-redux';
import PrivateRoute from './PrivateRoute'; 
 

import Root from '../routes/Root';


// All layouts/containers
import PrivateLayout from '../layouts/Private.js';
import PublicLayout from '../layouts/Public.js'; 
import PrintLayout from '../layouts/Print.js'; 



// Account related

// const ForgetPassword = React.lazy(() => import('../pages/account/ForgetPassword'));

const LandingScreen = React.lazy(() => import('../pages/account/Landing')); 

const Login = React.lazy(() => import('../pages/account/Login')); 

const Logout = React.lazy(() => import('../pages/account/Logout'));
// const Register2 = React.lazy(() => import('../pages/account2/Register2'));
// const Confirm2 = React.lazy(() => import('../pages/account2/Confirm2'));
// const ForgetPassword2 = React.lazy(() => import('../pages/account2/ForgetPassword2'));
// const LockScreen2 = React.lazy(() => import('../pages/account2/LockScreen2'));




// Public pages:

const HelpPage = React.lazy(() => import('../pages/public/HelpPage.js'));
const AboutPage = React.lazy(() => import('../pages/public/AboutPage.js'));
const PrivacyPage = React.lazy(() => import('../pages/public/PrivacyPage.js'));


// Logged-in pages:

const DashboardScreen = React.lazy(() => import('../pages/dashboard/DashboardScreen.js'));



const CalendarScreen = React.lazy(() => import('../pages/calendar/CalendarScreen.js'));



const ProjectViewScreen = React.lazy(() => import('../pages/projects/ProjectViewScreen.js')); 
const ProjectOverviewScreen = React.lazy(() => import('../pages/projects/ProjectOverviewScreen.js'));



const ReportsOverviewScreen = React.lazy(() => import('../pages/reports/ReportsOverviewScreen.js'));



const ActivityScreen = React.lazy(() => import('../pages/profile/ActivityScreen.js'));

const DeletionsScreen = React.lazy(() => import('../pages/profile/DeletionsScreen.js'));

const SearchScreen = React.lazy(() => import('../pages/other/SearchScreen.js'));



const TeamInviteScreen = React.lazy(() => import('../pages/teams/TeamInviteScreen.js'));
const TeamUpdateScreen = React.lazy(() => import('../pages/teams/TeamUpdateScreen.js'));
const TeamViewScreen = React.lazy(() => import('../pages/teams/TeamViewScreen.js'));
const TeamOverviewScreen = React.lazy(() => import('../pages/teams/TeamOverviewScreen.js'));


// const LogsDashboard = React.lazy(() => import('../pages/logs/logs-overview.js'));

    // const LogsViewPage = React.lazy(() => import('../pages/logs/logs-view.js'));

// const SettingsDashboard = React.lazy(() => import('../pages/settings/settings-overview.js'));

// pages
const ProfileUpdateScreen = React.lazy(() => import('../pages/profile/ProfileUpdateScreen.js'));

// const Profile2 = React.lazy(() => import('../pages/profile2'));
// const ErrorPageNotFound = React.lazy(() => import('../pages/error/PageNotFound'));
const PageNotFound = React.lazy(() => import('../pages/error/PageNotFound.js'));


// const ErrorPageNotFoundAlt = React.lazy(() => import('../pages/error/PageNotFoundAlt'));
const ServerError = React.lazy(() => import('../pages/error/ServerError'));

// - other
const Maintenance = React.lazy(() => import('../pages/error/Maintenance'));
const WidgetTestScreen = React.lazy(() => import('../pages/other/WidgetTestScreen.js'));


// const Landing = React.lazy(() => import('../pages/landing'));




const loading = () => <div className=""></div>;



type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>,
};



const LoadComponent = ({ component: Component, layout }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);



const AllRoutes = () => {



    return useRoutes([

        { 
            path: '/', 
            element: <LoadComponent component={LandingScreen} />
        },

        {
            // public routes
            path: '/',
            element: <PublicLayout />,
            children: [

                { 
                    path: 'login', 
                    element: <LoadComponent component={Login} /> 
                },
                {
                    path: '/maintenance',
                    element: <LoadComponent component={Maintenance} />,
                },
                {
                    path: 'error-404',
                    element: <LoadComponent component={PageNotFound} />,
                },
                {
                    path: 'error-500',
                    element: <LoadComponent component={ServerError} />,
                },
                {
                    path: '*',
                    element: <LoadComponent component={PageNotFound} />
                }

            ],
        },

        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute roles={'Admin'} component={PrivateLayout} />,
            children: [

                // Overall dashboard

                { 
                    path: 'logout', 
                    element: <LoadComponent component={Logout} /> 
                },

                {
                    path: 'dashboard',
                    element: <LoadComponent component={DashboardScreen} />
                },

                {
                    path: 'login', 
                    element: <LoadComponent component={Login} /> 
                },

                {
                    path: 'help',
                    element: <LoadComponent component={HelpPage} />
                },

                {
                    path: 'about',
                    element: <LoadComponent component={AboutPage} />
                },

                {
                    path: 'privacy',
                    element: <LoadComponent component={PrivacyPage} />
                },





                {
                    path: 'widgets',
                    element: <LoadComponent component={WidgetTestScreen}  />
                },




                {
                    path: 'projects/:projectid',
                    element: <LoadComponent component={ProjectViewScreen} />
                },
                {
                    path: 'projects',
                    element: <LoadComponent component={ProjectOverviewScreen} />
                },




                {
                    path: 'teams/:teamid/update',
                    element: <LoadComponent component={TeamUpdateScreen} />
                },
                {
                    path: 'teams/:teamid/invite',
                    element: <LoadComponent component={TeamInviteScreen} />
                },
                {
                    path: 'teams/:teamid',
                    element: <LoadComponent component={TeamViewScreen} />
                },
                {
                    path: 'teams',
                    element: <LoadComponent component={TeamOverviewScreen} />
                },





 
                {
                    path: 'calendar/user/:id',
                    element: <LoadComponent component={CalendarScreen} />
                },
                {
                    path: 'calendar',
                    element: <LoadComponent component={CalendarScreen} />
                },







                {
                    path: 'reports',
                    element: <LoadComponent component={ReportsOverviewScreen} />
                },





                {
                    path: 'profile',
                    element: <LoadComponent component={ProfileUpdateScreen} />
                },





                {
                    path: 'activity',
                    element: <LoadComponent component={ActivityScreen} />
                },





                {
                    path: 'search',
                    element: <LoadComponent component={SearchScreen} />
                },





                {
                    path: 'deletions',
                    element: <LoadComponent component={DeletionsScreen} />
                },


               


                // Catchall for 404 page:
                {
                    path: '*',
                    element: <LoadComponent component={PageNotFound} />
                }
            ],
        },
    ]);
};

export { AllRoutes };
