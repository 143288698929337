
// @flow
import React, { Suspense, useCallback, useEffect, useState, useContext } from 'react';
import { Outlet } from 'react-router-dom';

import config from '../config.js';
import { customAPI } from '../helpers/api.js';

// Components
// import PublicAppFrame from '../pages/public/PublicAppFrame.js';
import AppMasthead from '../components/layout/AppMasthead.js';
import AppMain from '../components/layout/AppMain.js';
import AppFooter from '../components/layout/AppFooter.js';
import PublicHeader from "../pages/public/PublicHeader";

// components
import TopBar from './TopBar.js';
import ToDo from '../components/ToDo.js';
import Loader from '../components/Loader.js';
import ErrorScreen from '../pages/error/Error.js';


// React


// Components

// helpers 

// styles
import 'bootstrap/dist/css/bootstrap.min.css';

// Global contexts
import { currentUserContext } from '../helpers/currentUserProvider.js';


const PublicLayout = ({ children, hideHeader }, state) => {


    const [ currentUser, setCurrentUser ] = useContext( currentUserContext );

    // General state prop handlers:
    const [error, setError] = useState(null);
    const [isLoaded, setMenuIsLoaded] = useState(false);
    const [menuItems, setMenuItems] = useState([]);
    const [userIsLoaded, setUserIsLoaded] = useState(false);
    const [companyIsLoaded, setCompanyIsLoaded] = useState(false);


    return (
        <>
            <div className="page" id="page" role="document">

                <PublicHeader />

                <section id="main-content" role="main" className="region  region--main">                

                    <Suspense>
                        <Outlet />
                    </Suspense>

                </section>

                <AppFooter />

            </div>

        </>
    );
};

export default PublicLayout;
