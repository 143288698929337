
import {Link} from 'react-router-dom';


const Logo = ({ className }) => {

    return (
        <Link to="/login" className={className}>
            <span className="logo">
                gwaith
                <span className="subtitle">work schedule</span>			
            </span>
        </Link>
    );

}

export default Logo;
