
// console.info( 'process.env: ', process.env );

const config = {
    API_URL: process.env.REACT_APP_API_URL,
    API_VERSION: 'v3.0',
    // DEBUG_MODE: true
    DEBUG_MODE: false
};

export default config;
 