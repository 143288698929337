
import { useState } from 'react';
import { Link } from 'react-router-dom';


const AppFooter = () => {

    const [year, setYear] = useState( new Date().getFullYear() );

    return (
        <footer id="footer" className="region  uppercase content--tiny">
            <div className="container  py-3">
                <div className="row justify-content-end">
                    <div className="col-auto text-end">

                        <p>
                            {/* <Link className="px-1" href="/about">About</Link> 
                            • 
                            <Link className="px-1" href="/help">Help</Link> 
                            •  */}
                            <Link className="px-1 normal" href="/privacy">Privacy</Link> 
                            • 
                            <span className="px-1">© 2014 - {year} </span>
                            • 
                            <Link className="px-1" href="http://www.jonathanharvey.net" rel="noopener">Jonathan Harvey</Link>
                            • 
                            <Link className="px-1" href="https://www.waters-creative.co.uk" rel="noopener">Waters</Link>
                        </p>

                    </div>
                </div>
            </div>
        </footer>
    );
}

export default AppFooter;
