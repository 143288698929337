
// Redux
import { configureStore } from '@reduxjs/toolkit'

// Viewpanel reducer
import viewPanelReducer from './reducers/viewPanelSlice.js';
import messagesReducer from './reducers/messagesSlice.js';
import calendarReducer from './reducers/calendarSlice.js';


export default configureStore({
    reducer: {

        calendar: calendarReducer,

        viewPanel: viewPanelReducer,

        messages: messagesReducer
        
    }
})
