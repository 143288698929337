
// @flow
import React, { useState } from 'react';

// Components
import AppMasthead from '../components/layout/AppMasthead';
import AppMain from '../components/layout/AppMain';
import AppFooter from '../components/layout/AppFooter';
import PrivateHeader from "../pages/private/PrivateHeader";

// components
import Loader from '../components/Loader.js';
import ErrorScreen from '../pages/error/Error.js';

// helpers 

// styles
import 'bootstrap/dist/css/bootstrap.min.css';

// Global contexts
import { currentUserContext } from '../helpers/currentUserProvider.js';




const PrivateLayout = ({ children }) => {


    // General state prop handlers:
    const [error, setError] = useState(null);
    const [isLoaded, setMenuIsLoaded] = useState(true);


    return (    
        <div id="gwaith-app" role="document" className="page   test-layout">

            <PrivateHeader />

            <section id="main-content" role="main" className="region  region--main">                

                <AppMain>
                    {children}
                </AppMain>

            </section>

            <AppFooter />

        </div>
    );
}

export default PrivateLayout;
