
// @flow
import React from 'react';
import { Provider } from 'react-redux'

// Structure/Redux
import store from './store.js';
import Routes from './routes/Routes';

// Themes
import './assets/sass/Sass.scss';

// Helpers
import CurrentUserProvider from './helpers/currentUserProvider.js';
// import ViewPanelProvider from './helpers/ViewPanelProvider.js';





/**
 * Main app component
 */
const App = (props) => {


    return (
        <Provider store={store}>
            <CurrentUserProvider>
                {/* <ViewPanelProvider> */}

                    <Routes />

                {/* </ViewPanelProvider> */}
            </CurrentUserProvider>
        </Provider>
    );
};

export default App;
