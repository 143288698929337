
import React from 'react';
import {Outlet} from 'react-router-dom';


const AppMain = ({classes, children}) => {

    classes = 'region  region--main  ' + classes || '';

    return (
        <section id="main-content" 
                 role="main" 
                 data-view="dashboard" 
                 data-date="" 
                 data-userid="" 
                 className="region  region--main">
            <Outlet  />
        </section>
    );
}

export default AppMain;
