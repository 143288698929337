
// React
import React, { useContext, useState, useEffect } from 'react';
import {Link} from 'react-router-dom';

// Components
import NavTeamMenu from './NavTeamMenu.js';
import { Loader } from 'react-bootstrap-typeahead';
import { NavNotificationDropdown } from './NavNotificationDropdown.js';

// Bootstrap related
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

// Helpers
import { customAPI } from '../../helpers/api.js';
import { formatDate } from '../../helpers/DateFunctions.js';

// Context
import { currentUserContext } from '../../helpers/currentUserProvider.js';




const SearchResults = ({ projectResults, userResults, taskResults, keywords, setKeywords }) => {


    const onClickCloseSearch = (e) => {
        e.preventDefault();
        setKeywords(null);
        return false;
    }


    const onKeyPressCloseSearch = (e) => {
        switch(e.which){

            case 27:
                setKeywords(null);
                break;

            default:
                break;
        }
        return true;
    }


    const onClickElsewhereCloseSearch = (e) => {
        
        if (e.target.closest('a')){
            // link
        } else if (e.target.closest('button')){
            // 
        } else if (e.target.closest('.qs-results')){
            // 
        } else if (e.target.closest('.navbar-search')){
            // 
        } else {
            setKeywords(null);
        }

        return true;
    }


    useEffect(() => {        
        window.addEventListener( 'keydown', onKeyPressCloseSearch );
        return () => window.removeEventListener( 'keydown', onKeyPressCloseSearch );
    },[]);

    useEffect(() => {        
        window.addEventListener( 'click', onClickElsewhereCloseSearch );
        return () => window.removeEventListener( 'click', onClickElsewhereCloseSearch );
    },[]);




    if (!keywords){

        return (
            <></>
        );

    } else {

        return (
            <div id="qs-results" className="qs-results">

                <a href="#" className="qs-results__close" onClick={onClickCloseSearch}>
                    Close
                    <span className="material-symbols-outlined">close</span>
                </a>

                <div className="row  gx-3">


                    {userResults ? (
                        <div id="qs-results-users" className="">
                            <h4 className="px-4 fs-6 strong">Users</h4>
                            <p>{JSON.stringify(userResults)}</p>
                        </div>
                    ) : <></>}


                    {projectResults ? (
                        <div className="col-12 col-lg-6">
                            <h4 className="px-4 fs-6 strong">Projects</h4>
                            <ul id="qs-results-projects" className="m-0 p-0 qs-results__group  qs-results-projects">

                                {projectResults.map((result) => {
                                    return (
                                        <NavSearchProjectResult project={result} key={'project-' + result.projectid} />
                                    )
                                })}
                                
                                {projectResults.length > 5 ? (
                                    <li className={'qs-result pt-3'}>
                                        <a className="qs-result__link  d-inline-flex align-items-center" href={'/search?q=' + keywords}>
                                            <span className="material-symbols-outlined me-1">search</span> 
                                            More results...
                                        </a>
                                    </li>
                                ) : <></>}

                                <li className={'qs-result ' + (projectResults.length > 5 ? '' : 'pt-3')}>
                                    <a className="qs-result__link  d-inline-flex align-items-center" href="/projects">
                                    <span className="material-symbols-outlined me-1">folder_open</span> 
                                        Browse all projects...
                                    </a>
                                </li>

                            </ul>
                        </div>
                    ) : <></>}



                    {taskResults ? (
                        <div className="col-12 col-lg-6">
                            <h4 className="px-4 fs-6 strong">Tasks</h4>
                            <ul id="qs-results-tasks" className="m-0 p-0  qs-results__group  qs-results-tasks">

                                {taskResults.map((result) => {
                                    return (
                                        <NavSearchTaskResult task={result} key={'task-' + result.taskid} />
                                    )
                                })}

                                {taskResults.length > 5 ? (
                                    <li className="qs-result">
                                        <a className="qs-result__link  d-inline-flex align-items-center" href={'/search?q=' + keywords}>
                                            <span className="material-symbols-outlined me-1">search</span> 
                                            More results...
                                        </a>
                                    </li>
                                ) : <></>}

                            </ul>
                        </div>
                    ) : <></>}

                </div>

            </div>
        )
    }
}

const NavSearchProjectResult = ({ project, options }) => {
    
    let complete = (project.status === 100 ? true : false);

    let completeIcon = (project.status === 100 ? <span className="material-symbols-outlined me-1">check</span> : '');

    let metaInfo = <div className="qs-result__info smaller uppercase">
        {project.timings.recurring ? (
            <span className="material-symbols-outlined me-1">replay</span>
        ) : <></>} 
        Project
        <span className="px-1">•</span>
        {project.statusName}
    </div>

    return (
        <>
            <li className={'qs-result ' + (complete ? 'qs-result--complete' : '')}>
                <a className="qs-result__link" href={'/projects/' + project.projectid}>
                    {completeIcon}
                    {project.name}
                    {metaInfo}
                </a>
            </li>
        </>
    )
}

const NavSearchTaskResult = ({ task, options }) => {

    let taskDate = 'Floating';

    if (task.event > 0) {
        // yyyy-mm-dd HH:ii:ss
        // 0123456789012345678
        taskDate = formatDate( task.eventDateTime, 'dd/MM/yyyy');
    }

    return (
        <li className="qs-result">
            <a className="qs-result__link" href={'/tasks/' + task.taskid}>
                {task.name}
                <span className="qs-result__info smaller uppercase">
                    {taskDate} 
                    <span className="px-1">•</span>
                    {task.status === 100 ? (
                        <>
                            <span className="complete">{task.userName}</span> 
                            <span className="px-1">•</span>
                        </>
                    ) : <></>}
                    {task.owner.name}
                </span>
            </a>
        </li>
    )
}




const NavSearch = ({ team, options }) => {


    const [currentUser] = useContext(currentUserContext);

    const [taskResults, setTaskResults] = useState();
    const [projectResults, setProjectResults] = useState();
    const [userResults, setUserResults] = useState();
    const [keywords, setKeywords] = useState('');


    let keywordTimer = 0;


    useEffect(() => {

        if (!keywords || keywords === ''){
            return () => {}
        }

        customAPI.get('projects/me?filters[keyword]=' + keywords + '&paging[start]=0&paging[count]=6', currentUser, (result) => {
            setProjectResults(result);
        }, (error) => {
            // 
        });

    }, [currentUser, keywords]);


    useEffect(() => {

        if (!keywords || keywords === ''){
            return () => {}
        }

        customAPI.get('tasks/me?filters[keyword]=' + keywords + '&paging[start]=0&paging[count]=6', currentUser, (result) => {
            setTaskResults(result);
        }, (error) => {
            // 
        });

    }, [currentUser, keywords]);




    const getTeamUserNames = ( teams ) => {

        let found = [];
        let foundIds = [];

        teams.forEach((team) => {
            team.users.forEach((user) => {

                if (foundIds.indexOf(user.userid) !== false){
                    // 
                } else if (user.name.indexOf(keywords) !== false){
                    found.push(user);
                    foundIds.push(user.userid)
                }
            });
        })

        return found;
    }

    useEffect(() => {

        if (!keywords || keywords === ''){
            return () => {}
        }

        debugger;

        setUserResults( 
            getTeamUserNames(currentUser.teams) 
        );

    }, [currentUser, keywords]);



    


    const onKeyDownSearch = (event) => {

        if (event.target.value.length < 3){
            return false;
        }  

        if (event.which === 13) {
            document.getElementById("navbar-form").onsubmit();
            return false;
        }

        if (event.which === 27) {
            setKeywords('');
            return false;
        }

        clearTimeout(keywordTimer);
        keywordTimer = setTimeout(() => {
            setKeywords(event.target.value.trim());
        }, 300);

        return false;
    }


    return (
        <Nav className="navbar-form navbar-search  navbar-right">
            <form action="/search" method="get" className="nav navbar-form navbar-search  px-3  navbar-right">

                <div className="form-group  d-flex align-items-center m-0">
                    <input type="text"
                        name="q"
                        id="js-search"
                        placeholder="Search"
                        defaultValue=""
                        autoComplete="off"
                        onKeyUp={onKeyDownSearch}
                        className="form-control  tiny" />
                    <button type="submit" className="px-2">Search</button>
                </div>

                <SearchResults
                    keywords={keywords}
                    projectResults={projectResults}
                    userResults={userResults}
                    taskResults={taskResults}
                    setKeywords={setKeywords} />

            </form>
        </Nav>
    );


}

export default NavSearch;
